exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-about-page-index-tsx": () => import("./../../../src/templates/AboutPage/index.tsx" /* webpackChunkName: "component---src-templates-about-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-dining-page-index-tsx": () => import("./../../../src/templates/DiningPage/index.tsx" /* webpackChunkName: "component---src-templates-dining-page-index-tsx" */),
  "component---src-templates-events-page-index-tsx": () => import("./../../../src/templates/EventsPage/index.tsx" /* webpackChunkName: "component---src-templates-events-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-hotels-page-index-tsx": () => import("./../../../src/templates/HotelsPage/index.tsx" /* webpackChunkName: "component---src-templates-hotels-page-index-tsx" */),
  "component---src-templates-works-page-index-tsx": () => import("./../../../src/templates/WorksPage/index.tsx" /* webpackChunkName: "component---src-templates-works-page-index-tsx" */)
}

