import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight2: '#EAECEF',
      neutralLight1: '#FAFAFA',
      neutralDark2: '#434343',
      neutralDark1: '#222222',
      primaryDark: '#1D1D1D',
      primaryLight: '#FFFFFF',
      danger: '#ED0F36',
    },
  },
  fontFamily: {
    heading: "'Jost', sans-serif",
    paragraph: "'Manrope', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
